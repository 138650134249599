export enum RequestStatus {
  'IN_PROGRESS',
  'INACTIVE',
  'FAILED',
}

export enum SaveStatus {
  'INACTIVE',
  'IN_PROGRESS',
}
